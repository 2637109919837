@import 'variables';

.mp-story {
  padding: 20px;

  .mp-story-section {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;

    h2 {
      color: $primary-color;
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    p {
      color: $primary-text;
      font-size: 1.2rem;
      line-height: 1;
      padding-left: 10px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
