@font-face {
  font-family: 'NeueMontreal-Med';
  src: url('./Components/Fonts/NeueMontreal-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeueMontreal-Reg';
  src: url('./Components/Fonts/NeueMontreal-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body, html, #root {
  height: 100%;
  margin: 0;
  background-color: #F9F9F9;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content > * {
  padding-bottom: 20px;
}

/* .App > *:not(.footer) {
  flex: 1; 
} */



/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
