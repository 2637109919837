@import 'variables';

.products-section {
    padding: 25px;
    min-height: 500px;

    .products-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      h2 {
        font-size: 1.5rem;
        color: $primary-text;
      }

      .see-all {
        background: none;
        border: none;
        color: $primary-text;
        cursor: pointer;
        font-weight: bold;
        text-decoration: none;
      }
    }

    .c-products-section {
        padding: 10px;
    }

    .products-slider {
      display: flex;
      padding: 10px 0;
      pointer-events: auto;
      overflow-x: auto;
      overscroll-behavior-inline: contain;

        &::-webkit-scrollbar {
          width: 25px;
          height: 7px;
          background: $background-primary;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          border-radius: 50px;
          cursor: pointer;
          height: 5px;
        }

        &:hover::-webkit-scrollbar {
          height: 7px; 
          background: $background-primary;
        }

        &:hover::-webkit-scrollbar-thumb {
          background-color: $primary-color;
        }
    
      -ms-overflow-style: none;
      scrollbar-width: none;


      .product-card {
        min-width: 270px;
        margin-right: 50px;

        .product-image-wrapper {
          width: 300px;
          height: 300px;
          position: relative;
          overflow: hidden;
          border-radius: 8px;
          background-color: $background-secondary;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        
        .product-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;

          img {
            width: 300px; 
            height: 300px; 
            object-fit: contain; 
            border-radius: 8px;
            margin: 0 auto;
            display: block;
          }

          .heart-icon {
            position: absolute;
            top: 10px;
            right: 8px;
            width: 40px;
            height: 40px;
            color: $primary-text;
            background-color: $background-primary;
            border-radius: 50%;
            padding: 5px;
            border: none;
            cursor: pointer;
            font-size: 1.2rem;
            z-index: 1;
          }
        }
      }

        .product-name,
        .product-price {
          text-align: left;
          color: $primary-text;
        }
    }
    }
        .products-grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 10px;

          .product-card {
            .product-image {
              position: relative;
              width: 100%;
              border-radius: 8px;
              margin-bottom: .5rem;
            
              img {
                width: 275px;
                height: 275px;
                border-radius: 8px;
                object-fit: cover;
                margin-top: 2rem;

              }

              .heart-icon {
                position: absolute;
                top: 35px;
                // left: 10px;
                right: 8px;
                width: 40px;
                height: 40px;
                background-color: $background-primary;
                border-radius: 50%;
                padding: 5px;
                border: none;
                cursor: pointer;
                font-size: 1.2rem;
                z-index: 1;
              }
              
            }
    
            .product-name,
            .product-price {
              text-align: left;
              color: $primary-text;
        }
      }
    }

    .fb-note {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
      
        p {
          color: $secondary-text;
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
  }

  @media only screen and (max-width: 768px)  {
    .products-section {
        padding: 20px;
        min-height: unset;
  
        .products-header {
          display: flex;
    
          h2 {
            font-size: 1.5rem;
          }
    
          .see-all {
            background: none;
          }
        }  
  
        .products-slider {
          display: flex;
          gap: 0px;
  
          &::-webkit-scrollbar {
          display: none; 
        }
  
          .product-card {
            min-width: 150px;
  
            .product-image-wrapper {
              width: 185px;
              height: 185px;
            
            .product-image {
              width: 100%;
              height: 100%;
    
              img {
                max-width: 185px;
                max-height: 185px;
              }
            }
          }
        }
        }
          .products-grid {
              grid-template-columns: repeat(2, 1fr);
              gap: 15px;
    
             .product-card {
                .product-image {
                  width: 169px;
                  height: 169px;
                
                  img {
                    margin-top: 0;
                    width: 175px;
                  height: 175px;
                  }
    
              .heart-icon {
              top: 10px;
             }
            }
          }
        }
      }
    
  }