@import 'variables';

.track-page {
  padding: 20px;
  background-color: $background-primary;
  position: relative;
  max-width: 1200px;
  width: 800px;
  margin: 125px auto 0 auto;


  .track-header {
    // text-align: center;
    // margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .track-back-button {
      // position: absolute;
      // top: 24px;
      // left: 10px;
      // color: $primary-color;
      // cursor: pointer;
        background-color: $primary-color;
        color: #fff;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.9rem;
  
        &:hover {
          background-color: darken($primary-color, 10%);
        }
    }

    .track-title {
      color: $primary-color;
      font-size: 1.5rem;
    }

    .order-id {
      font-size: 1rem;
      color: $primary-text;
    }
  }

  .delivery-details, .driver-info, .products-section {
    margin-bottom: 20px;
    background-color: $background-primary;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .section-title {
      color: $primary-color;
      margin-bottom: 10px;
      font-size: 1.2rem;
    }

    .text {
      margin: 5px 0;
      color: $primary-text;
      font-size: 1rem;
    }

    .w-text{
      color: rgb(212, 97, 168);
      font-size: 0.9rem;
    }

    .t-code{
      color: $primary-color;
      font-size: 25px;
      padding-left: 5px;
    }
  
      .delivery-status {
        margin-top: 20px;
        font-weight: bold;
        color: $primary-color;

        span{
            color: $primary-text;
        }
      }
  }

  .tracking-iframe-container {
    margin: 20px 0;
    iframe {
      width: 100%;
      height: 400px;
      border: none;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .tracking-timeline {
    position: relative;
    margin-bottom: 20px;
    background-color: $background-primary;
    padding: 15px 33px;
    
    border: none;

    .timeline-entry {
      position: relative;
      padding: 10px;

      &:before {
        content: '';
        position: absolute;
        left: -8px; 
        top: 5px;
        width: 3px;
        height: 100%;
        background-color: $primary-color;
      }

      &:after {
        content: '';
        position: absolute;
        left: -13px;
        top: 28%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        background-color: $primary-color;
        border-radius: 50%;
      }

      .timeline-time {
        font-size: 0.85rem;
        color: $primary-text;
      }

      .timeline-status {
        margin: 5px 0;
        color: $primary-text;
        font-size: 1rem;
      }
    }
  }

  .show-more-btn {
    display: block;
    width: 100%; 
    margin: 10px 0;
    background-color: $primary-color;
    color: #fff;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
  }

  .product-item {
    position: relative;
    padding-bottom: 8px;
  }
  
  .add-review-link {
    position: absolute;
    right: 25px;
    top: -5px;
    color: $primary-color;
    text-decoration: none;
    font-weight: bold;
    
    &hover {
    text-decoration: none;
  }
    .r-icon{
      position: absolute;
      right:-26px;
      top: -3px;
    }
  }
  
  .divider {
    border-top: 1px solid #e0e0e0;
    margin: 8px 0;
  }

  .total-amount {
    font-weight: bold;
    margin-top: 10px;
    color: $primary-text;
  }
}

@media only screen and (max-width: 768px) {
  .track-page {
    margin-top: 66px;
    width: 100%;
  
    .tracking-timeline {
      padding: 15px 0 15px 20px;
    }
  }
}