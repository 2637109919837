@import "variables";

.search-page {
  padding: 20px;
  background-color: $background-primary;
  margin: 120px auto;
  max-width: 1200px;
  min-width: 1200px;

  .search-header {
    font-size: 24px; 
    color: $primary-color;
    margin-bottom: 20px;
  }

  .loading-text,
  .error-text,
  .no-results {
    text-align: center;
    font-size: 18px;
    color: $secondary-text;
  }

  .error-text {
    color: $error-text;
  }

  .results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
  }

  .result-card {
    background-color: transparent;
    border-radius: 8px;
    padding: 15px;
    text-align: left;
    transition: 0.3s;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    &:hover {
      transform: translateY(-5px);
      // box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    img {
      width: 200px;
      height: 200px;
      max-width: 200px;
      min-width: 200px;
      max-height: 200px;
      min-height: 200px;
      object-fit: cover;
      border-radius: 5px;
    }

    .placeholder {
      width: 100%;
      height: 150px;
      background: $secondary-text;
      display: flex;
      align-items: center;
      justify-content: center;
      color: transparent;
      font-size: 14px;
      border-radius: 5px;
    }

    h3 {
      margin-top: 5px;
      font-size: 16px;
      color: $primary-text;
      text-align: left;
    }

    p {
      font-size: 14px;
      color: $secondary-text;
      margin-top: 2px;
      text-align: left;
    }
  }

  .product-link,
  .merchant-link {
    text-decoration: none;
    color: inherit;
  }
}

// Mobile view adjustments
@media only screen and (max-width: 768px) {
  .search-page {
    padding: 15px;
    width: 100%;
    min-width: 0;
    margin: 80px auto
  }

  .results-grid {
    grid-template-columns: 2fr;
  }

  .result-card {
    padding: 5px;

    img {
      max-width: 120px;
      min-width: 120px;
      max-height: 120px;
      min-height: 120px;
      height: 120px;
      width: 120px;
    }

    h3 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }
}
