$primary-color: #832b99;
$secondary-color: #a239c0;
$primary-text: #010101;
$secondary-text: #807089;
$background-primary: #F9F9F9;
$background-secondary: #FCF8FD;
$error-text: #ff3333;

//Status Color
$to-pay-color: #FF6500;
$to-ship-color: #A91079;
$to-receive-color: #4C3BCF;
$completed-color: #832b99;
$cancelled-color: #ff3333;
$return-refund-color: #3d3d3d;