@import 'variables';

.review-page {
    padding: 0;
    background-color: $background-primary;
    max-width: 1200px;
    margin: 100px auto;
    width: 800px;


  .title-text {
    color: $primary-text;
    text-align: center;
    background-color: $primary-color;
    padding: 1.2rem;
    margin: 10px 0;
    color: $background-primary;
  }

  .order-id {
    color: $primary-text;
    font-size: 0.9rem;
    padding: 0 0 0 1rem;
  }

  .rev-back-button {
    position: absolute;
    top: 118px;
    left: 570px;
    color: $background-primary;
    cursor: pointer;
  }

  .product-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem;

    .product-image {
      width: 100%;
      max-width: 100px;
      object-fit: cover;
      border-radius: 8px;
    }

    .product-name {
      font-size: 1.2rem;
      font-weight: bold;
      color: $primary-text;
      padding: 0.5rem;
    }

    .product-variant {
      font-size: 0.9rem;
      color: $primary-text;
      padding: 0.5rem;
    }
  }

  .stars-rating {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .star-icon {
      font-size: 2rem;
      cursor: pointer;
      margin: 0 0.2rem;
    }
  }

  .review-message {
    width: 100%;
    height: 100px;
    max-width: 770px;
    min-width: 770px;
    min-height: 100px;
    max-height: 100px;
    margin: 1rem 1rem 0.5rem 1rem;
    font-size: 1rem;
    color: $primary-text;
    background-color: $background-primary;
    border: 1px solid $primary-color;
    border-radius: 5px;
    padding: 1rem;
  }

  .dropzone {
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    color: $primary-text;
    cursor: pointer;
    margin: 0 1rem 1rem 1rem;

    &:hover {
      background-color: rgba(162, 89, 255, 0.1);
    }
    .cam-prop{
        color: #ccc;
    }
  }

  .submit-button {
    width: 100%;
    background-color: $primary-color;
    color: $background-primary;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    max-width: 777px;
    margin: 1rem auto;
    display: block;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }

  .file-name {
    color: $primary-text;
    text-align: center;
    margin-top: 0.5rem;
  }
}

@media only screen and (max-width: 768px) { 
  .review-page {
    margin-top: 80px;
    width: 100%;

    .title-text {
      color: $primary-text;
      text-align: center;
      background-color: $primary-color;
      padding: 1rem;
      margin: 0 0 10px 0;
      color: $background-primary;
    }

    .rev-back-button {
      top: 86px;
      left: 10px;
    }

  .review-message {
    max-width: 400px;
    min-width: 400px;
  }

  .submit-button {
    max-width: 390px;
  }
}


}