@import 'variables';

.profile-container {
  max-width: 800px;
  width: 800px;
  margin: 100px auto;
  padding: 2rem;
  text-align: left;
  background-color: $background-primary;
  border-radius: 8px;
  position: relative;

.profile-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: $primary-color;
}

.profile-detail {
  color: $primary-text;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.profile-divider {
  margin: 1rem 0;
  border: 0;
  border-top: 1px solid $primary-color;
}

.profile-icons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  
  .icon {
    display: none;
    position: absolute;
    top: 2.5rem;
    right: 2rem;

    .icon-image {
      font-size: 1.5rem;
      color: $primary-text;
    }
  }
}

.logout-button {
  display: flex;
  align-items: center;
  color: $primary-text;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 2rem;

  .logout-icon {
    color: $primary-color;
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }

  &:hover {
    text-decoration: none;
  }
}
}

@media only screen and (max-width: 768px) { 
  .profile-container {
    max-width: 430px;
    margin-top: 75px;
    width: 100%;
  }
}