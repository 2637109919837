@import 'variables';

.cart-page {
  padding: 20px;
  margin-bottom: 100px;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;

.cart-header {
  margin: 100px 0 30px 0;
  font-size: 2rem;
  color: $primary-color;
}

.cart-divider {
  margin-bottom: 20px;
  border: none;
  height: 1px;
  background-color: $primary-color;
}
}

.merchant-section {
  border-radius: 12px;
  padding: 15px;
  background-color: $background-primary;
  margin-bottom: 20px;
  margin-left: 8px;
  width: 800px;

  .merchant-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;

    .merchant-logo {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      // object-fit: cover;
      margin-right: 15px;
      background-color: $primary-color;
      object-fit: contain;
    }

    .merchant-name {
      font-size: 1.2rem;
      color: $primary-text;
      margin: 0;
    }

    .select-merchant {
      margin-right: 10px;
      position: relative;
      appearance: auto;
      width: 15px;
      height: 15px;
      border: 1px solid $primary-text;
      cursor: pointer;

      &:checked {
        accent-color: $primary-color;
        background-color: $primary-color;
        border: 1px solid $primary-color;
      }
    }
  }
}

.cart-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: $background-primary;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  position: relative;
  transition: box-shadow 0.3s;
  margin: 5px;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .select-item {
    position: absolute;
    left: -25px;
    appearance: auto;
    width: 13px;
    height: 13px;
    border: 1px solid $primary-text;
    border-radius: 50%;
    cursor: pointer;

    &:checked {
      accent-color: $primary-color;
      background-color: $primary-color;
      border: 1px solid $primary-color;
    }
  }

  .product-image {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    object-fit: cover;
    border: 1px solid #ccc;
  }

  .cart-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    
  .cart-product-name {
    font-size: 1.2rem;
    color: $primary-text;
    margin: 0 0 1px;
    font-weight: 600;
  }

  .cart-product-price{
    font-size: 0.9rem;
    color: $primary-text;
  }
  .cart-product-variant,
  .cart-product-subtotal {
    font-size: 0.9rem;
    color: $primary-text;
    margin: 5px 0;
  }

  .cart-product-subtotal {
    font-weight: bold;
  }

  .quantity-controls {
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      width: 30px;
      height: 30px;
      font-size: 1.2rem;
      background-color: $primary-color;
      color: $background-primary;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    span {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

.remove-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: $primary-color;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: darken($primary-color, 15%);
  }
}
}

.cart-footer {
position: fixed;
bottom: 0;
left: 0;
right: 0;
background: $background-primary;
box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
padding: 25px 360px;
display: flex;
justify-content: space-between;
align-items: center;

.total-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  .total-text {
    font-size: 1.2rem;
  }

  .total-amount {
    font-size: 1.5rem;
    font-weight: bold;
    color: $primary-text;
  }
}

.checkout-button {
  background: $primary-color;
  color: $background-primary;
  padding: 10px 30px;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  transition: background 0.2s;

  &:hover {
    background: darken($primary-color, 10%);
  }
}
}

@media only screen and (max-width: 768px) {
  .cart-page {
    padding: 20px;
    margin-bottom: 100px;
    width: 100%;
  }
  
  .merchant-section {
    width: 100%;
    .merchant-header{
      .select-merchant {
        left: 16px;
      }
    }
  }
  
  .cart-item {
    margin: 3px;
  }
  
  .cart-footer {
  padding: 25px;
  }
}