@import 'variables';

.support-page {
  background-color: $background-primary;
  max-width: 1200px;
  margin: 100px auto;
  padding: 20px;

  .support-layout {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;

      h1 {
        flex: 1;
        font-size: 2.5rem;
        color: $primary-color;
        margin-right: 20px;
      }

      .support-container {
        flex: 2;
      }
    }
  }

  h1 {
    color: $primary-color;
    margin-bottom: 20px;
    font-size: 1.5rem;

    @media only screen and (min-width: 768px) {
      font-size: 2rem;
      padding-top: 50px;
    }
  }
}

.support-container {
  background-color: $background-primary;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.support-form {
  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      color: $primary-text;
    }

    input, textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      color: $primary-text;
    }
  }

  .attachment-group {
    .dropzone {
      padding: 20px;
      border: 2px dashed #ccc;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .error-alert {
    color: $error-text;
    font-size: 0.85rem;
    margin-top: 3px;
  }

  .submit-button {
    background-color: $primary-color;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: $background-primary;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup {
  background: $background-primary;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.popup-content {
  p {
    color: $primary-text;
    margin-bottom: 10px;
  }

  .home-button {
    background-color: $primary-color;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: $background-primary;
    cursor: pointer;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}


@media only screen and (max-width: 768px) {
  .support-page {
    padding: 10px;
  }

  .support-layout {
    flex-direction: column;
  }

  h1 {
    font-size: 1.5rem;
  }
}
