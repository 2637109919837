@import 'variables';

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
  background-color: $background-primary;
  color: $primary-text;
  font-family: 'NeueMontreal-Reg', sans-serif;

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-text {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .footer-link {
    color: $primary-text;
    text-decoration: none;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: $primary-color;
    }
  }
}
