@import "variables";

.reset-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $background-primary;

  .logo {
    width: 100px;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 1.5rem;
    color: $primary-text;
    text-align: left; 
    width: 100%; 
    max-width: 300px;
  }

  p {
    color: $secondary-text;
    margin-bottom: 20px;
    text-align: left; 
    width: 100%;
    max-width: 300px; 
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-container {
      position: relative;
      margin-bottom: 15px;

      input {
        width: 300px;
        padding: 10px 40px 10px 15px;
        border: 1px solid $primary-color;
        border-radius: 5px;
        font-size: 1rem;
        outline: none;
      }

      button {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.2rem;
        color: $primary-color;
      }
    }

    .submit-button {
      width: 300px;
      padding: 10px;
      background-color: $primary-color;
      color: $background-primary;
      font-size: 1rem;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .reset-password {
    h1 {
      font-size: 1.2rem;
      max-width: 250px; 
    }

    p {
      max-width: 250px;
    }

    form .input-container input {
      width: 250px;
    }

    form .submit-button {
      width: 250px;
    }
  }
}
