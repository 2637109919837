@import 'variables';

.mp-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 125px;
  text-align: center;

  p {
    color: $secondary-text;
    font-size: 1.2rem;
    font-weight: 500;
  }
}

@media only screen and (max-width: 768px)  {

    .mp-gallery {
        padding: 75px 75px 150px 75px;
      
        p {
          font-size: 1.2rem;
        }
      }

}