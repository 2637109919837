@import 'variables';

.order-page {
  padding: 0;
  background-color: $background-primary;
  margin-top: 200px;
  max-width: 1200px;
  min-width: 900px;
  margin: 100px auto;
}

.order-header {
  text-align: center;
  background-color: $primary-color;
  color: $background-primary;
  padding: 15px;
  margin: 20px auto;
  width: 100%;
}

.tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  overflow-x: auto; 
  padding: 5px 0;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tab-button {
  background-color: $background-primary;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  color: #333;
  transition: background-color 0.3s;
  white-space: nowrap;

  &:hover {
    background-color: $background-primary;
  }

  &.active {
    background-color: $primary-color;
    color: $background-primary;
  }
}

.orders-container {
  display: flex;
  flex-direction: column;
}

.order-card {
  position: relative;
  padding: 16px;
  border: 1px solid #ddd;
  background-color: $background-primary;
  border-radius: 5px;
  margin: 10px 0;

  .order-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.order-id {
  font-size: 0.9rem;
  font-weight: bold;
}

.secure-delivery-code {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: right;

  span {
    font-size: 1.8rem;
    font-weight: bold;
    color: $primary-color;
  }
}

  .order-status-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

.order-status {
  font-weight: bold;
}

.cancel-order-btn {
  background-color: $primary-text;
  color: $background-primary;
  border: none;
  padding: 5px 10px;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $primary-color;
  }
}

.divider {
  border: none;
  height: 2px;
  background-color: $primary-color;
  margin: 10px 0;
}

.next-icon {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 24px;
  color: $primary-color;
  cursor: pointer;
  text-decoration: none;
}

}

.no-order{
  padding-top: 20px;
  text-align: center;
}

.order-id,
.order-status,
.item-count,
.total-amount {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.merchant-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;

  .store-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: $primary-color;
    object-fit: contain;
  }

  .store-name {
    font-size: 1rem;
    font-weight: bold;
    flex-grow: 1;
  }

  .cancel-order-btn {
    background-color: $primary-text;
    color: $background-primary;
    border: none;
    padding: 5px 10px;
    font-size: 0.9rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-left: auto; 

    &:hover {
      background-color: $primary-color;
    }
  }
}

.product-list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 15px;
  margin: 20px 30px;

  .product-item {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .product-image {
      width: 30px;
      height: 30px;
      object-fit: cover;
      margin-right: 10px;
      border-radius: 8px;
    }

    .product-name {
      font-size: 0.9rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .order-page {
    margin-top: 80px;
    width: 100%;
    min-width: auto;
  }

  .tabs {
    gap: 10px;
    padding: 5px;
    justify-content: flex-start;
  }

  .tab-button {
    padding: 10px 15px;
    font-size: 0.9rem;
  }

  .order-card {
    width: 100%;
  }
}
