@import 'variables';

.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../Components/Assets/background.png') no-repeat center center;
  background-size: cover;
}

.signin-card {
  background-color: transparent;
  padding: 2rem;
  border-radius: 8px;
  width: 320px;
  text-align: center;
}

.signin-logo {
  width: 180px;
  margin-bottom: 1.5rem;
}

.signin-title {
  margin-bottom: 1rem;
  text-align: left;
  color: $primary-text;
}

// .signin-text {
//   margin-bottom: 1rem;
//   text-align: left;
//   color: $secondary-text;
//   font-size: 13px;
// }


.signin-form {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
}

.signin-input {
   padding: 0.5rem 0;
   border: none;
   border-bottom: 1px solid $primary-text;
   font-size: 1rem;
   color: $primary-text;
   outline: none;
   text-align: left;
   background-color: transparent;

}

.sign-in-password-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.sign-in-password-container input {
  width: 100%;
  padding-right: 2.5rem; 
}


.sign-in-eye-icon {
  position: absolute;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: $primary-text;
  font-size: 1.2rem;
  padding: 0.2rem;
}

.sign-in-eye-icon:focus {
  outline: none;
}

.signin-button {
  background-color: $primary-color;
  color: #ffffff;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}

.register-section {
  margin-top: 2rem;
}

.register-text {
  text-align: left;
  margin-bottom: 0.5rem;
  color: $primary-text;
}

.register-button {
  background-color: transparent;
  color: $primary-text;
  border: 1px solid $primary-text;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  width: 260px;
}

.si-error-message {
    color: $error-text;
    font-size: 0.8rem;
    text-align: left;
  }

  .forgot-password {
    margin-top: 0.5rem;
    text-align: center;
  }
  
  .forgot-password-link {
    font-size: 0.8rem; 
    color: $primary-color; 
    text-decoration: none;
  
    &:hover {
      text-decoration: underline;
    }
  }
