@import 'variables';

*,
*::before,
*::after {
  box-sizing: border-box;
}

.home {
  background-color: $background-primary;
  width: 100%;
  overflow-x: hidden;
  max-width: 1200px;
  margin: 0 auto;

  //bannerSection.tsx
  .carousel {
    width: 100%;
    margin: 0;

    .carousel-item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $background-primary;
      position: relative;

    //   .carousel-image {
    //     padding-top: 7rem;
    //     width: 100%;
    //     object-fit: contain;
    //   }
    // }

    .carousel-image,
      .carousel-video {
        width: 100%;
        height: auto;
        max-height: 80vh;
        object-fit: cover;
        display: block;
        border-radius: 8px;
        padding-top: 8rem;
      }

      .carousel-video {
        max-height: 75vh;
      }
    }

    .slick-dots {
      position: absolute;
      left: 50%;
      top: 50rem;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;

      li {
        width: 1rem;
        height: 1rem;
        margin: 0 0.5rem;

        button {
          width: 1rem;
          height: 1rem;
          background-color: rgba(255, 255, 255, 0.7);
          border-radius: 50%;
          transition: background-color 0.3s;

          &:before {
            content: '';
            display: block;
            width: 1rem;
            height: 1rem;
            background-color: transparent;
            border-radius: 50%;
          }
        }

        &.slick-active button {
          background-color: $primary-color;
        }
      }
    }
  }

  //favoritesSection.tsx
  .favorites {
    padding: 1rem;
    background-color: $background-primary;

    h2 {
      font-size: 1.5rem;
      font-family: "NeueMontreal-Reg";
      color: $primary-text;
      margin-bottom: 1.43rem;
    }

    .favorites-list {
      display: flex;
      gap: 1rem;
      flex-wrap: nowrap;
      -webkit-overflow-scrolling: touch;
      pointer-events: auto;

      overflow-x: auto;
      overscroll-behavior-inline: contain;

      &::-webkit-scrollbar {
        width: 25px;
        height: 7px;
        background: $background-primary;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 50px;
        cursor: pointer;
        height: 5px;
      }

      &:hover::-webkit-scrollbar {
        height: 7px; 
        background: $background-primary;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: $primary-color;
      }

      .favorite-item {
        position: relative;
        text-align: left;
        min-width: 11rem;
        flex: 0 0 auto;

        a {
          text-decoration: none;
          color: $primary-text;
          cursor: pointer;

          .favorite-image {
            width: 100%;
            max-width: 200px;
            border-radius: 8px;
            object-fit: cover;
            filter: blur(10px);
            margin-bottom: 0.5rem;
            transition: filter 0.5s ease-out;
            height: 200px;
            width: 200px;

            &.loaded {
              filter: blur(0);
            }
          }

          .favorite-name {
            font-size: 1rem;
            font-weight: bold;
            margin-top: 0.5rem;
            color: $primary-text;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }

          .favorite-price {
            font-size: 0.9rem;
            color: $primary-text;
            text-align: left;
          }
        }

        .heart-icon {
          position: absolute;
          top: 5%;
          right: 5%;
          width: 40px;
          height: 40px;
          background-color: $background-primary;
          border-radius: 50%;
          padding: 5px;
          border: none;
          cursor: pointer;
          z-index: 1;
        }
      }
    }
  }


//followedSection.tsx
  .followed-merchants {
    padding: 1rem;

    .followed-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
  
    h2 {
      font-size: 1.5rem;
      color: $primary-text;
      font-family: "NeueMontreal-Reg";
    }

    .see-all {
      background: none;
      border: none;
      color: $primary-text;
      cursor: pointer;
      font-weight: bold;
      text-decoration: none;
    }
  }
    .f-merchant-list-wrapper {
      overflow-x: auto;
  }
  
    .f-merchant-list {
      display: flex;
      gap: 1rem;
      overflow-x: auto;
      flex-wrap: nowrap;
      padding: 0.5rem 0;
      -webkit-overflow-scrolling: touch;
      pointer-events: auto;

  
      &::-webkit-scrollbar {
        display: none;
      }

      &.scrollable {
        flex-wrap: nowrap;
      }

      &.grid-view {
        display: grid;
        justify-content: space-between;
        overflow-x: hidden;
        grid-template-columns: repeat(5, 1fr);
      }
    
      .f-merchant-item {
        position: relative;
        flex: 0 0 auto;
        width: 15rem;
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .card-overlay {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 50%;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
          border-radius: 16px;
          z-index: 1; 
        }

        .image-overlay {
          position: relative;
          display: inline-block;
          width: 100%;
        }
        
        .image-overlay img {
          width: 100%;
          height: auto;
          display: block;
        }
  
        .featured-content {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          flex-shrink: 0;
          border-radius: 16px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          width: 210px; 
          height: 300px; 
  
          img,
          iframe {
            width: 100%; 
            height: 100%; 
            object-fit: cover;
            
          }
        }

        .f-merchant-profile {
          position: absolute;
          top: 0;
          left: 1rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          background: transparent;
          padding: 0.8rem 0.5rem 0;
          border-radius: 2rem;
          color: $background-primary;
          font-family: "NeueMontreal-Reg";
          font-size: 0.9rem;
          z-index: 2;
  
          .f-merchant-hero {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            object-fit: cover;
          }
  
          .f-merchant-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $background-primary;
          }
      }
      }
    }

    .f-modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
  
    .f-modal-content {
      background: #fff;
      padding: 1rem;
      position: relative;
      max-width: 500px;
      min-width: 500px;
      max-height: 1000px;
      min-height: 500px;
      width: auto;
      height: auto;
      overflow: hidden;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(8px);
      transform: rotate(0); 

      img{
        max-width: 100%;
        max-height: 100%;
        height: auto; 
        width: auto;  
        object-fit: contain;
      }
    }

    .f-close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 1.5rem;
      color: $primary-color;
      cursor: pointer;
      transition: color 0.3s ease;
  
      &:hover {
        color: darken($primary-color, 10%);
      }
    }
  }



//merchantSection.tsx
  .merchant-sections {
    padding: 0;

    .merchant-category {
      padding: 1rem;

      .category-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        

        h3 {
          font-size: 20px;
          color: $primary-text;
          font-weight: bolder;
        }

        .see-all {
          background: none;
          border: none;
          color: $primary-text;
          cursor: pointer;
          font-weight: bold;
          text-decoration: none;
          padding: 8px 12px;
          border-radius: 5px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          transition: 0.3s;
        }
      }

      .merchant-list-container {
        position: relative;


      .merchant-list {
        display: flex;
        gap: 1rem;
        flex-wrap: nowrap;
        width: 100%;
        max-width: 1200px;
        position: relative;

        overflow-x: auto;
        overscroll-behavior-inline: contain;

        &::-webkit-scrollbar {
          width: 25px;
          height: 7px;
          background: $background-primary;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          border-radius: 50px;
          cursor: pointer;
          height: 5px;
        }

        &:hover::-webkit-scrollbar {
          height: 7px; 
          background: $background-primary;
        }

        &:hover::-webkit-scrollbar-thumb {
          background-color: $primary-color;
        }

        &.grid-view {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          justify-content: flex-start;
        }

        .merchant-item {
          text-align: center;
          flex: 0 0 auto;
          width: 100%;
          max-width: 250px;
          border-radius: 8px;

          a {
            text-decoration: none;
            color: $primary-text;
            cursor: pointer;

            .merchant-image-wrapper {
              position: relative;
              width: 100%;
              height: 0;
              padding-bottom: 100%;
              border-radius: 8px;
              overflow: hidden;
              background-color: $background-secondary;
              box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            
              .merchant-hero {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                border-radius: 8px;
              }
            
              .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 8px;
              }
            
              .merchant-logo {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 150px; 
                height: 150px;
                object-fit: contain;
                border-radius: 8px;
                background: transparent;
              }
            }

            .merchant-name {
              font-size: 1rem;
              font-weight: bold;
              color: $primary-text;
              text-align: left;
              white-space: normal;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 15px;
              overflow-wrap: break-word;
              display: -webkit-box; 
              display: -moz-box; 
              display: flex;
              -webkit-line-clamp: 3; 
              -moz-line-clamp: 3; 
              line-clamp: 3; 
              -webkit-box-orient: vertical;
              -moz-box-orient: vertical; 
              box-orient: vertical; 


            }
          }
        }

        .scroll-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 40px;
          height: 40px;
          border: none;
          border-radius: 50%;
          background-color: rgba(var(--primary-color), 0.6);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          z-index: 2;
        }
        
        .scroll-button.left {
          left: 10px;
        }
        
        .scroll-button.right {
          right: 10px;
        }
        
        .scroll-button:hover {
          background-color: rgba(var(--primary-color), 0.8);
        }

      }

    } 
    }
  }

  // .carousel-image,
  .merchant-image {
    filter: blur(10px);
    transition: filter 0.5s ease-out;
  }

  // .carousel-image.loaded,
  .merchant-image.loaded {
    filter: blur(0);
  }

  .others-button {
    text-align: left;
    margin: 1rem 0;
  
    .and-more {
      font-size: 1.5rem;
      // color: $primary-text;
      // background-color: transparent;
      // border: none;
      // margin-bottom: 1rem;
      // font-family: NeueMontreal-Med;
      // cursor: pointer;
      background-color: $primary-color;
      color: $background-primary;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      width: 100%;
      cursor: pointer;
      border: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .home {
    width: 100%;
    overflow-x: hidden;
  
    .carousel {
      width: 100%;
  
      .carousel-item {
        display: flex;
        position: relative;

        .carousel-image,
        .carousel-video {
          max-height: 40vh; 
          object-fit: cover;
          padding-top: 5.6rem;
          border-radius: 0px;
        }
  
        // .carousel-image {
        //   padding-top: 5.6rem;
        //   width: 100%;
        //   object-fit: contain;
        // }
      }
  
      .slick-dots {
        top: 18.5rem;
  
        li {
          margin: 0 0.5rem;
  
          button {
            border-radius: 50%;
          }
        }
      }
    }
  
    .favorites {
      padding: 1rem;
  
      h2 {
        font-size: 1.3rem;
      }
  
      .favorites-list {
        display: flex;

        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;

  
        .favorite-item {
          position: relative;
  
          a {
            text-decoration: none;
  
            .favorite-image {
              width: 100%;
              max-width: 150px;
              height: 150px;
            }
  
            .favorite-name {
              font-size: 1rem;
            }
  
            .favorite-price {
              font-size: 0.9rem;
            }
          }
  
          .heart-icon {
            left:70%;
          }
        }
      }
    }

    .followed-merchants {
      padding: 1rem;
    
      .f-merchant-list {
        display: flex;
    
        &::-webkit-scrollbar {
          display: none;
        }
  
        &.scrollable {
          flex-wrap: nowrap;
        }
  
        &.grid-view {
          grid-template-columns: repeat(2, 1fr);

          .f-merchant-item {
            width: 13rem;

          .featured-content {
            width: 190px; 
            height: 277px;
          }
        }
        }
      
        .f-merchant-item {
          width: 15rem;
    
          .featured-content {
            width: 210px; 
            height: 297px;
    
            img,
            iframe {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
    
          .f-merchant-profile {
            position: absolute;
    
            .f-merchant-hero {
              object-fit: cover;
            }
    
            .f-merchant-name {
              text-overflow: ellipsis;
            }
          }
        }
      }

      .f-modal-content {
        max-width: 90vw;
        max-height: 70vh;
        width: 100%;
        height: 100%;


        img{
          max-width: 100%;
          max-height: 100%;
          height: 70vh;; 
          width: 90vw;  
          object-fit: contain;
        }
      }
    
    }

    
    .merchant-sections {
      padding: 0;
  
      .merchant-category {
        margin-bottom: 0;
  
        .category-header {
          display: flex;
          justify-content: space-between;
          
  
          h3 {
            font-size: 1.4rem;
          }
  
          .see-all {
            color: $primary-text;
            display: flex !important;
            font-size: 14px;
            padding: 5px 10px;
          }
        }

        .merchant-list-container {
          position: relative;  
  
        .merchant-list {
          display: flex;
          overflow-x: auto;
  
          &.grid-view {
            grid-template-columns: repeat(2, 1fr);
          }

          &::-webkit-scrollbar {
            display: none;
          }

          -ms-overflow-style: none;
          scrollbar-width: none;

  
          .merchant-item {
            max-width: 185px;
            min-width: 180px;
  
            a {
              color: $primary-text;
  
              .merchant-image-wrapper {
                position: relative;
                width: 100%;
                height: 0;

              
                .merchant-hero {
                  width: 100%;
                  height: 100%;
                }
              
                .overlay {
                  width: 100%;
                  height: 100%;
                }
              
                .merchant-logo {
                  width: 100px; 
                  height: 100px;
                }
              }
  
              .merchant-name {
                font-size: 1rem;
                color: $primary-text;
              }
            }
          }

          .scroll-button {
            display: flex !important;
          }
        }
      }
      }
    }

    .others-button {
      text-align: center;
  
      .and-more {
        width: 95%;
      }
    }
  } 
}
