@import 'variables';

.terms-container {
  max-width: 850px;
  margin: 95px auto;
  padding: 20px;
  background-color: $background-primary;
  color: $primary-text;
  font-family: "NeueMontreal-Reg", "NeueMontreal-Med";
  text-align: justify;

  header {
    border-bottom: 2px solid $primary-color;
    padding-bottom: 10px;
    margin-bottom: 20px;

    h1 {
      font-size: 2rem;
      color: $primary-color;
    }

    .last-modified {
      font-size: 0.9rem;
      color: $secondary-text;
      font-weight: normal;
    }
  }

  .terms-intro {
    margin-bottom: 30px;

    h2 {
      font-size: 1.5rem;
      color: $primary-color;
    }

    p {
      font-size: 1rem;
      line-height: 1.6;
    }
  }

  .terms-section {
    margin-bottom: 30px;

    h3 {
      font-size: 1.3rem;
      color: $primary-color;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
      margin-bottom: 10px;
    }

    ul.custom-list {
      list-style-type: none !important;
      padding-left: 0;
      counter-reset: listCounter;

      li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 5px;
        line-height: 1.5;
        font-size: 0.95rem;

        &:before {
          counter-increment: listCounter;
          content: "(" counter(listCounter, lower-alpha) ")";
          position: absolute;
          left: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .terms-container {
    padding: 25px;

    header h1 {
      font-size: 1.5rem;
    }

    .terms-intro h2 {
      font-size: 1.3rem;
    }

    .terms-section h3 {
      font-size: 1.1rem;
    }

    ul .content-item {
      font-size: 0.9rem;
      line-height: 1.4;

      .formatted-content {
        flex-direction: column;
      }
    }
  }
}
