@import "variables";

.mp-merchant-page {
  background: $background-primary;
  margin: 0 auto;
  max-width: 1200px;
  min-width: 1200px;

.mp-merchant-banner {
  position: relative;
  background: center/cover no-repeat fixed;
  height: 500px;
  display: flex;
  align-items: center;
  margin-top: 100px;
}

.mp-back-button {
  position: fixed;
  top: 125px;
  left: 275px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 12px;
  border-radius: 50%;
  color: $primary-color;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.mp-merchant-info {
  text-align: left;
  padding: 20px;
  position: relative;
  margin-top: -50px; 
}

.mp-merchant-header {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.mp-merchant-profile {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  border: 4px solid $background-primary;
  background: $primary-color;
  object-fit: contain;
}

.mp-merchant-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mp-merchant-location {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  color: $secondary-text;
  margin-top: -5px;

  .mp-location-icon {
    color: $primary-color;
    font-size: 18px;
  }
}

.mp-merchant-name {
  font-size: 24px;
  color: $primary-text;
  font-weight: bold;
  margin: 45px 0 0 0;
}

.mp-merchant-schedule {
  color: $secondary-text;
  font-size: 14px;
  margin-bottom: 5px 0;
}

.mp-vacation-warning {
  display: flex;
  align-items: center;
  background-color: $background-secondary;
  color: $primary-color;
  border: 1px solid $primary-color;
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  border-radius: 5px;
  font-weight: 500;

  .mp-v-warning-icon {
    margin-right: 10px;
    font-size: 15px;
  }

  .mp-vw-text{
    font-size: 12px;
  }
}

.mp-merchant-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.mp-follow-button,
.mp-share-button {
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
  font-size: 1rem;
}

.mp-follow-button {
  display: flex;
  align-items: center;
  justify-content: center; 
  border: none;
  cursor: pointer;
  background: $primary-color;
  color: $background-primary;
  border: none;
  transition: background-color 0.3s, color 0.3s;

  &.following {
    background-color: $primary-text;
    color: $background-primary;
  }

  .follow-icon {
    margin-right: 8px; 
  }
}

.mp-share-button {
  border: 1px solid $primary-color;
  color: $primary-color;
  background: white;
}

.mp-merchant-tabs {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding: 15px;
}

.mp-tab {
  background: $background-primary;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: $secondary-text;
  cursor: pointer;
  padding: 10px 20px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
  }
}

.mp-tab.active {
  color: $primary-color;
  border-bottom: 3px solid $primary-color;
}

.mp-share-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mp-share-modal-content {
  margin-top: 0;
  color: $primary-text;
  text-align: center;
  background: $background-primary;
  padding: 30px;
  width: 25%;
  border-radius: 10px;
}

.mp-share-buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: $primary-color;
  color: $background-primary;
  border: none;
  padding: 8px 12px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.mp-share-buttons button:hover {
  background: $primary-text;
}

.mp-close-modal {
  margin-top: 10px;
  background: $primary-text;
  color: $background-primary;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.mp-dropdown {
  position: relative;
}

.mp-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: $background-primary;
  border: 1px solid $primary-color;
  border-radius: 5px;
  width: 150px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.2s, opacity 0.2s ease-in-out;
  z-index: 1000;
}

.mp-dropdown:hover .mp-dropdown-menu {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.mp-dropdown-item {
  padding: 10px;
  font-size: 14px;
  color: $secondary-text;
  cursor: pointer;
  transition: background 0.3s;
}

.mp-dropdown-item:hover {
  background: $primary-color;
  color: $background-primary;
}

.mp-dropdown-icon{
  margin-left: 10px;

}

.mp-product-filter {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.mp-product-filter select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  cursor: pointer;
}

.mp-product-item {
  cursor: pointer;
  pointer-events: auto;
}
.mp-dropdown-menu, .mp-tab {
  pointer-events: auto;
}

}

@media only screen and (max-width: 768px) {
.mp-merchant-page {
  width: 100%;
  min-width: unset;

  .mp-merchant-banner {
    height: 200px;
    margin-top: 80px;
    background-size: contain;
    background-position-y: 10px;
  }

  .mp-back-button {
    top: 85px;
    left: 10px;
    padding: 8px;
    position: fixed;
  }

  .mp-merchant-header {
    flex-direction: column;
    align-items: stretch;
    text-align: left;
  }

  .mp-merchant-profile {
    max-width: 150px;
    max-height: 150px;
    min-width: 150px;
    min-height: 150px;
    border-width: 3px;
  }

  .mp-merchant-name {
    margin: 0;
    font-size: 28px;
  }

  .mp-merchant-schedule {
    font-size: 14px;
  }

  .mp-vacation-warning {
    width: 95%;
  
    .mp-v-warning-icon {
      font-size: 15px;
    }
  
    .mp-vw-text{
      font-size: 12px;
    }
  }

  .mp-merchant-actions {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .mp-follow-button,
  .mp-share-button {
    width: 100%; 
    padding: 10px;
  }

  .mp-merchant-tabs {
    flex-direction: row;
    justify-content: center;
    gap: 60px;
  }

  .mp-tab {
    font-size: 14px;
    padding: 5px;
  }

  .mp-share-modal-content {
    width: 100%;
  }
}
}
