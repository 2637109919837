@import 'variables';

.cl-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cl-modal-content {
  width: 90%;
  max-width: 600px;
  padding: 20px;
  background-color: $background-primary;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.cl-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cl-add-address-button {
  background-color: $primary-color;
  color: $background-primary;
  padding: 5px 10px;
  font-size: 0.9rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.cl-modal-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: $primary-text;
  margin-bottom: 1rem;
}

.cl-contact-item {
  display: flex;
  position: relative;
  padding: 10px 0 30px 0;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: $background-primary;

  .cl-contact-radio {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 8px;
  }

  .cl-contact-details {
    margin-left: 40px;
    flex: 1;
    text-align: left;
    font-size: 0.9rem;
    color: $primary-text;

    .cl-p {
      margin: 4px 0;
    }

    .cl-default-toggle {
      position: absolute;
      bottom: 9px;
      left: 40px;
      display: flex;
      align-items: center;

      .cl-label {
        display: flex;
        align-items: center;
        cursor: pointer;

        .cl-input[type="checkbox"] {
          appearance: none;
          width: 32px;
          height: 16px;
          background-color: #ccc;
          border-radius: 16px;
          position: relative;
          cursor: pointer;
          transition: background-color 0.2s;

          &:checked {
            background-color: $primary-color;
          }

          &:checked::before {
            left: 19px;
          }

          &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: 12px;
            height: 12px;
            background-color: $background-primary;
            border-radius: 50%;
            transition: left 0.2s;
          }
        }

        .cl-span {
          margin-left: 10px;
          font-size: 0.85rem;
          color: $secondary-text;
        }
      }
    }
  }

  .cl-edit-icon {
    position: absolute;
    top: 16px;
    right: 40px;
    font-size: 1.5rem;
    color: $primary-color;
    cursor: pointer;
  }
  .cl-delete-icon {
    position: absolute;
    top: 16px;
    right: 10px;
    font-size: 1.5rem;
    color: $primary-color;
    cursor: pointer;
  }

}

.cl-modal-save-button {
  background-color: $primary-color;
  color: $background-primary;
  width: 100%;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.cl-modal-close-button {
  background-color: $primary-text;
  color: $background-primary;
  width: 100%;
  margin-top: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

input[type="radio" i] {
  accent-color: $primary-color; 
  margin-right: 5px; 
}

