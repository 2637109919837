@import 'variables';

.others-page {
  padding: 2rem 1rem;
  background-color: $background-primary;
  max-width: 1200px;
  margin: 100px auto;
  overflow-x: hidden;
  width: 100%;

  h3 {
    font-size: 1.5rem;
    color: $primary-text;
    margin-bottom: 1.5rem;
    text-align: left;
  }

  .merchant-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .merchant-item {
      text-align: center;
      border-radius: 8px;
      overflow: hidden;
      background-color: $background-secondary;
      text-decoration: none;

      .merchant-image-wrapper {
        width: 250px;
        height: 250px;
        position: relative;
        margin: 5px;

        .merchant-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 8px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        }
      }

      .merchant-name {
        font-size: 1rem;
        font-weight: bold;
        color: $primary-text;
        margin-top: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media only screen and (max-width: 768px) {

    .others-page {
        max-width: 1200px;
      
        h2 {
          font-size: 2rem;
        }
      
        .merchant-list {
          display: flex;
      
          .merchant-item {
            text-align: center;
      
            .merchant-image-wrapper {
              width: 175px;
              height: 175px;
              position: relative;
      
              .merchant-image {
                width: 100%;
                height: 100%;
              }
            }
      
            .merchant-name {
              font-size: 1rem;
            }
          }
        }
      }
}