@import 'variables';

.favorites-page {
  padding: 120px 0 0 0;
  background-color: $background-primary;
  max-width: 1000px;
  margin: 0 auto;

  h1 {
    font-size: 1.5rem;
    color: $primary-text;
    font-family: NeueMontreal-Med;
    margin-bottom: 1rem;
  }

  .favorites-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;

    .favorite-item {
      position: relative; 
      border-radius: 8px;
      background-color: $background-primary;

      img {
        width: 100%;
        width: 194px;
        height: 194px;
        max-width: 194px;
        max-height: 194px;
        object-fit: cover;
        border-radius: 8px;
      }

      .product-info {
        padding: 0.5rem;
        
        .product-name {
          color: $primary-text;
          font-size: 1.1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          text-align: left;
        }

        .product-price {
          font-size: 1rem;
          color: $primary-text;
          text-align: left;
          margin-top: 0.25rem;
        }
      }

      .cart-icon {
        position: absolute;
        top: 15rem;
        right: 0.5rem;
        color: $primary-color;
        font-size: 1.25rem;
        cursor: pointer;
      }
      
      .heart-icon {
        position: absolute;
        color: $primary-text;
        top: 10px;
        right: 5px;
        width: 40px;
        height: 40px;
        background-color: $background-primary;
        border-radius: 50%;
        padding: 5px;
        border: none;
        cursor: pointer;
        font-size: 1.2rem;
        z-index: 1;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .favorites-page {
    padding: 100px 20px 20px 20px;
    background-color: $background-primary;
    width: 100%;
  
    .favorites-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
}