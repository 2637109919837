@import 'variables';

.order-details-page {
  padding: 20px;
  background-color: $background-primary;
  max-width: 1200px;
  min-width: 800px;
  margin: 125px auto 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;

  .od-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .od-back-button {
      background-color: $primary-color;
      color: #fff;
      padding: 8px 12px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 0.9rem;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    h2 {
      font-size: 1.5rem;
      margin: 0;
    }
  }

  .od-created-at,
  .od-order-ref,
  .od-status {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .od-status {
    font-weight: bold;
    color: $primary-text; 
    display: flex;
    justify-content: space-between;
    align-items: center;

    .od-track-delivery-btn {
        background-color: $primary-color;
        color: #fff;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

  }

  .od-shipping-header{
    font-size: 1.3rem;
    padding: 10px 0;
    color: $primary-color;
  }

  .od-divider {
    border: none;
    height: 2px;
    background-color: $primary-color;
    margin: 20px 0;
  }

  .od-merchant-details {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .od-store-logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
    }

    .od-store-name {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .od-product-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;

    .od-product-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 10px;
      margin-left: 45px;

      .od-product-image {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 8px;
      }

      .od-product-info {
        flex: 1;
        margin-left: 15px;

        .od-product-name {
          font-size: 1rem;
          font-weight: bold;
        }

        .od-product-variant {
          font-size: 0.9rem;
          color: $secondary-text;
        }
      }

      .od-product-subtotal {
        font-size: 1rem;
        color: $primary-text;
      }
    }
  }

  .od-payment-card {
    padding: 15px;
    background-color: $background-primary;
    border-radius: 8px;
    border: 1px solid #ddd;

    p {
      display: flex;
      justify-content: space-between;
      margin: 5px 0;
      font-size: 1rem;

      .od-right {
        color: $primary-text;
      }
    }

    .od-total {
        font-weight: bold;
        font-size: 1.2rem;
      }
  }

  @media only screen and (max-width: 768px) {
    min-width: auto;
    width: 100%;
    margin: 80px 0;
    border: none;
    border-radius: 0;

    .od-header {
      flex-direction: column;
      align-items: flex-start;

      h2 {
        margin-top: 10px;
      }
    }

    .od-merchant-details {
      flex-direction: column;
      align-items: flex-start;

      .od-store-logo {
        margin-bottom: 10px;
      }
    }

    .od-product-list {
      .od-product-item {
        flex-direction: column;
        align-items: flex-start;

        .od-product-subtotal {
          align-self: flex-end;
          margin-top: 5px;
        }
      }
    }
  }
}