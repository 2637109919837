@import 'variables';

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $background-primary;
    box-shadow: 0 1px 3px -2px black;
    position: fixed;
    width:100%;
    top: 0;
    right: 0;
    z-index: 1000;
    padding: 30px 100px;

.nav-logo {
    padding-left: 200px;
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;

    img {
      width: auto;
      height: auto;

      &:first-child {
        max-height: 150px;
        max-width: 150px; 
      }

      &:nth-child(2) {
        display: none;
        max-height: 80px;
        max-width: 130px; 
        
      }
    }
  }

  .nav-search {
    flex-grow: 1;
    max-width: 1000px;
    padding: 0px 80px;
    display: flex;
    justify-content: center;

    .search-bar {
      display: flex;
      align-items: center;
      width: 100%; 
      border: 1px solid #000;
      padding: 5px 10px;
      border-radius: 5px;
      gap: 10px;

      .search-icon {
        color: $primary-color;
      }

      input {
        flex-grow: 1;
        border: none;
        background-color: $background-primary;
        outline: none;
        font-family: 'NeueMontreal-Reg';
      }

      .close-icon {
        cursor: pointer;
        color: $primary-color;
      }
    }
  }

  .login-button {
    color: $background-primary;
    text-decoration: none;
    font-family: 'NeueMontreal-Reg';
    padding: 5px 10px;
    border-radius: 5px;
    background-color: $primary-color;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: lighten($primary-color, 10%);
    }
  }


.nav-icons {
    display: flex;
    list-style: none;
    padding-right: 200px;
    gap: 1.5rem;
    align-items: center;
    position: relative;
    justify-content: space-between;

    .fav-icon,.cart-icon,.track-icon,.search-icon,.msearch-icon {
    cursor: pointer;
    color: $primary-text;
    transition: color 0.3s ease;

    &:hover {
      color: $primary-color;
    }
  }


  .fav-icon-container,
  .cart-icon-container {
    position: relative;
    
    .fav-count,
    .cart-count {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: $primary-color;
      color: $background-primary;
      font-size: .8rem;
      padding: 2px 6px;
      border-radius: 50%;
    }
  }
}

  
.bur-overlay {
  position: fixed;
  top: 102px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1; 
}

  .bur-menu {
    position: absolute;
    top: 85%;
    right: 0;
    background-color: $background-primary;
    border: 1px solid $background-primary;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    width: 20%;
    height: 100vh;
    padding: 10px 20px;
    font-family: 'NeueMontreal-Reg';
    z-index: 999;

    .msearch, .msearch-icon, .msearch-bar, .mclose-icon{
      display: none;
    }

    ul {
      list-style: none;

      li {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        cursor: pointer;
        border-bottom: 1px solid $background-primary;

        &:last-child {
          border-bottom: none;
        }

        a {
          color: $primary-text;
          text-decoration: none;
          transition: color 0.3s ease;

          &:hover {
            color: $primary-color; 
          }
        }
        svg {
         margin-right: 5px;
        }
        .msearch-icon{
          display: none;
        }
      }
    }
  }
}       

@media only screen and (max-width: 768px)  {

  .navbar{
    padding: 40px 0px;

    .nav-logo {
      position: absolute;
      padding:0;
      left: 20px;

      img {
        width: auto;
      }
    }

    .nav-search, .search-icon {
    display: none; 
    }

    .login-button {
     padding: 5px 10px;
     border-radius: 6px;
    }

    .nav-icons {
      position: absolute;
      gap:10px;
      right:25px;
      padding:0;
      
        .fav-icon,.cart-icon,.track-icon,.search-icon,.msearch-icon {
          color: $primary-text;
        }

     .fav-icon-container,
     .cart-icon-container {
        position: relative;
    
      .fav-count,
      .cart-count {
        position: absolute;
        background-color: $primary-color;
        color: $background-primary;
        font-size: .8rem;
        padding: 2px 6px;
     }
    }
  }

  .bur-menu {
     width: 100%;
     height: auto;
     padding: 15px;
    
     .msearch, .msearch-icon, .msearch-bar, .mclose-icon{
      display: flex;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        cursor: pointer;
        border-bottom: 1px solid $background-primary;

        &:last-child {
          border-bottom: none;
        }

        a {
          color: $primary-text;
          text-decoration: none;
          transition: color 0.3s ease;

          &:hover {
            color: $primary-color; 
          }
        }

        svg {
            margin-right: 5px;
          }

          .msearch-icon{
            display: flex;
          }
        }
        
        li:first-child {
          display: flex;
          align-items: center;
          border: 1px solid #000;
          border-radius: 5px;
  
          input {
            flex-grow: 1;
            border: none;
            outline: none;
            background-color: $background-primary;
            padding: 5px;
            font-family: 'NeueMontreal-Reg';
          }
          .close-icon {
            cursor: pointer;
            color: $primary-color;
          }
          .msearch-icon{
            color:$primary-color;
          }
        }
      }
    }
  }
}