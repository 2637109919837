@import 'variables';

.fgpass-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $background-primary;
}

.fgpass-box {
  width: 400px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.fgpass-logo {
  width: 100px;
  margin-bottom: 1rem;
}

.fgpass-title {
  font-size: 1.5rem;
  color: $primary-text;
  margin-bottom: 0.5rem;
  text-align: left; 
}

.fgpass-subtitle {
  font-size: 0.9rem;
  color: $secondary-text;
  margin-bottom: 1.5rem;
  text-align: left; 
}

.fgpass-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fgpass-label {
  font-size: 0.9rem;
  color: $primary-text;
  text-align: left;
}

.fgpass-input {
  padding: 0.8rem;
  border: 1px solid $secondary-text;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;

  &:focus {
    border-color: $primary-color;
  }
}

.fgpass-button {
  padding: 0.8rem;
  background-color: $primary-color;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.fgpass-back {
  display: inline-block;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: $primary-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.fgpass-error-message {
  color: $error-text;
  font-size: 0.9rem;
  margin-top: 0.2rem;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .fgpass-box {
    width: 90%;
    padding: 1.5rem;
  }

  .fgpass-title {
    font-size: 1.25rem;
  }

  .fgpass-subtitle {
    font-size: 0.9rem;
  }

  .fgpass-input {
    font-size: 0.9rem;
  }

  .fgpass-button {
    font-size: 0.9rem;
  }
}
