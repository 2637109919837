@import 'variables';

.verify-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding: 20px;
  background-color: $background-primary;
  height: 100vh;
  text-align: left;
  margin: 0 auto;

  .verify-heading {
    color: $primary-color;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  .verify-text {
    color: $primary-text;
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .verify-button {
    background-color: $secondary-color;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    width: 25%;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($secondary-color, 10%);
    }
  }
}

@media only screen and (max-width: 768px) {
  .verify-container {
    padding: 15px;

    .verify-heading {
      font-size: 1.5rem;
    }

    .verify-text {
      font-size: 0.9rem;
    }

    .verify-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
}
