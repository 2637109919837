@import 'variables';

* {
  font-family: "NeueMontreal-Reg", "NeueMontreal-Med";
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  scroll-padding-top: 2rem;
  list-style: none, disc inside;
  text-decoration: none;
  font-size: 14px;
}
