@import 'variables';

.return-refund {
  max-width: 850px;
  margin: 95px auto;
  padding: 20px;
  background-color: $background-primary;
  font-family: "NeueMontreal-Reg", "NeueMontreal-Med";
  color: $primary-text;
  text-align: justify;

  header {
    border-bottom: 2px solid $primary-color;
    padding-bottom: 10px;
    margin-bottom: 20px;

    h1 {
      font-size: 2rem;
      color: $primary-color;
    }

    .last-modified {
      font-size: 0.9rem;
      color: $secondary-text;
      font-weight: normal;
    }
  }

  .return-refund-intro {
    margin-bottom: 30px;

    h2 {
      font-size: 1.5rem;
      color: $primary-color;
    }

    p {
      font-size: 1rem;
      line-height: 1.6;
    }
  }

  .return-refund-section {
    margin-bottom: 30px;

    h3 {
      font-size: 1.3rem;
      color: $primary-color;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
      margin-bottom: 10px;
    }

    ul {
      padding-left: 20px;

      .content-item {
        display: flex;
        align-items: flex-start;
        font-size: 0.95rem;
        margin-bottom: 5px;
        line-height: 1.5;

        .bullet {
          color: $primary-text;
          font-weight: bold;
          margin-right: 10px;
        }
      }

      .content-item.numbered {
        display: block;
        padding-left: 0;
        margin-bottom: 5px;
        line-height: 1.5;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .return-refund {
    padding: 25px;

    header h1 {
      font-size: 1.5rem;
    }

    .return-refund-intro h2 {
      font-size: 1.3rem;
    }

    .return-refund-section h3 {
      font-size: 1.1rem;
    }

    ul .content-item {
      font-size: 0.9rem;
      line-height: 1.4;

      &.numbered {
        padding-left: 0;
      }
    }
  }
}
