@import 'variables';

.product-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1150px;
    min-width: 1150px;
    margin: 0 auto;
  
  .product-banner {
      position: relative;
      width: 100%;
      height: 40rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $background-primary;
      background-size: contain;
      background-position: center;
  

    .back-arrow {
      position: fixed;
      top: 125px;
      left: 275px;
      background: rgba(255, 255, 255, 0.8);
      border: none;
      padding: 12px;
      border-radius: 50%;
      color: $primary-color;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      z-index: 10;
    }

    .banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.5s ease-in-out;
      padding-top: 5rem;
      filter: blur(10px);
      opacity: 0;
      &.loaded {
        filter: blur(0);
        opacity: 1;
      }
    }

    .carousel-indicators {
        position: absolute;
        bottom: 10px;
        display: flex;
        gap: 8px;
  
        .indicator {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #ddd;
          cursor: pointer;
          transition: background-color 0.3s;
  
          &.active {
            background-color: $primary-color;
          }
        }
      }
  }

  .product-content {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 1.5rem 0;
    gap: 2rem;

    .product-image-container {
        flex: 0 0 300px;

        img.product-image {
        width: 300px;
        height: 300px;
        object-fit: contain;
        border-radius: 16px;
        margin-top: 15px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .product-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 1.5rem;

      .product-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
  
      .product-name {
        font-size: 24px;
        font-weight: bold;
        color: $primary-text;
      }

      .heart-icon {
        background: none;
        border: none;
        cursor: pointer;
        position: relative;
        margin-left: 40px;
        color: $primary-text;
      }
    }

    .product-spec-table {
      width: 100%;
      margin: 10px 0;
    
      td {
        padding: 0px 2px;
        border: none;
      }
    
      .spec-name {
        font-weight: bold;
        width: 15%;
        background-color: $background-primary;
        color: $secondary-text;
        text-align: left;
      }
    
      .spec-value {
        text-align: left;
        color: $primary-text;
      }
    }
    
  
      .product-price {
        font-size: 20px;
        color: $primary-color;
        font-weight: bold;
      }
  
      h3 {
        font-size: 18px;
        margin-top: 15px;
        color: $primary-text;
      }

      .product-spec , .product-des{
        color: $primary-text;
      }

      .product-des{
        margin-bottom: 1rem;

        .show-more {
          color: $primary-color;
          cursor: pointer;
          font-size: larger;
          font-weight: bold;
        }
      }
  
      .product-actions {
        display: flex;
        gap: 15px;
        align-items: center;
  
        .add-to-cart {
          padding: 16px 30px;
          background-color: $primary-color;
          color: $background-primary;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          font-size: 1.3rem;
          
        }
      }
      }
    }


    .reviews-section {
      width: 100%;
      padding: 20px;
      background-color: $background-primary;
      margin-top: 2rem;
      border-radius: 8px;

      .reviews-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .reviews-title {
          font-size: 1.3rem;
          font-weight: bold;
          color: $primary-text;
        }
    
        .see-all-button {
          background: none;
          border: none;
          color: $primary-color;
          cursor: pointer;
          font-size: 1rem;
          font-weight: bold;
          padding: 0;
        }
    
      h2 {
        color: $primary-text;
        margin-bottom: 10px;
        font-size: 18px;
      }
      }

      .reviews-slider {
        display: flex;
        overflow-x: auto;
        gap: 20px;
        -webkit-overflow-scrolling: touch;
        pointer-events: auto;
        overscroll-behavior-inline: contain;

        &::-webkit-scrollbar {
          width: 25px;
          height: 7px;
          background: $background-primary;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          border-radius: 50px;
          cursor: pointer;
          height: 5px;
        }

        &:hover::-webkit-scrollbar {
          height: 7px; 
          background: $background-primary;
        }

        &:hover::-webkit-scrollbar-thumb {
          background-color: $primary-color;
        }

    
        &.grid-view {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;

          .review-card {
            min-width: 570px;
            max-width: 570px;
          }

        }
    
    
        .review-card {
          min-width: 400px;
          max-width: 400px;
          min-height: 275px;
          border: 1px solid #ddd;
          padding: 10px;
          border-radius: 5px;
          background-color: $background-primary;
          position: relative;

          .review-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
      
            .review-stars {
              font-size: 2.5rem;
              color: $primary-text;
            }
      
            .review-date {
              font-size: 12px;
              color: $primary-text;
            }
          }
    
          .review-message {
            margin: 10px 0;
            font-size: 1.3rem;
            color: $primary-text;
          }
    
          .review-user {
            font-size: 1rem;
            color: $primary-text;
            margin-bottom: 2rem;
          }

          .review-image-container {
            position: absolute;
            bottom: 10px;
            left: 10px;
            cursor: pointer;
        
            .review-image {
              width: 60px;
              height: 60px;
              object-fit: cover;
              border-radius: 8px;
            }
          }
        }
      }
      .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        cursor: pointer;
      
        .modal-content {
          position: relative;
          width: 80%;
          max-width: 600px;
          background: $background-primary;
          border-radius: 16px;
          padding: 20px;
          
          .modal-image {
            width: 100%;
            height: auto;
            max-width: 600px;
            min-width: 150px;  
            min-height: 200px;  
            border-radius: 8px;
          }
          .modal-close-button {
            position: absolute;
            top: -16px;
            right: 260px;
            background: none;
            border: none;
            font-size: 20px;
            cursor: pointer;
            color: $primary-color;
            z-index: 10;
          }
        }
      }
    }

    .suggestions-section {
      width: 100%;
      padding: 20px;
      background-color: $background-primary;
      margin-top: 20px;
      border-radius: 8px;
    
      h2 {
        color: $primary-text;
        margin-bottom: 10px;
        font-size: 1.2rem;
      }
    
      .suggestions-slider {
        display: flex;
        overflow-x: auto;
        padding: 10px 0;
        -webkit-overflow-scrolling: touch;
        pointer-events: auto;

    
        &::-webkit-scrollbar {
          display: none;
        }
    
        -ms-overflow-style: none;
        scrollbar-width: none;
    
        .suggestion-card {
          min-width: 200px;
          max-width: 250px;
          margin-right: 15px;
          text-align: center;
    
          img {
            width: 100%;
            height: 75%;
            border-radius: 8px;
            object-fit: cover;
            transition: filter 0.5s ease-out;
            filter: blur(10px);
              &.loaded {
              filter: blur(0);
              opacity: 1;
            }
          }
    
          .suggestion-name {
            text-align: left;
            color: $primary-text;
          }
    
          .suggestion-price {
            text-align: left;
            color: $primary-text;
          }
        }
      }
  }

  .disclaimer{
    display: flex;
    justify-content: flex-start;

  .p-disclaimer{
    text-align: left;
    color: $secondary-text;
    margin-right: 700px;
  }
}

}
@media only screen and (max-width: 768px) {
    .product-page {
      max-width: 100%;
      min-width: 100%;
    
      .product-banner {
        width: 100%;
        height: 35rem;
 
      .back-arrow {
        top: 85px;
        left: 10px;
        padding: 8px;
        position: fixed;
      }
    }

    .product-content {
      flex-direction: column;

      .product-info{
        .product-spec-table {      
          .spec-name {
            width: 50%;
          }
        }
      }

      

      .product-image-container {
        display: none;
      }

      
    }
    .reviews-section {
    .reviews-slider{

      &::-webkit-scrollbar {
        display: none; 
      }

      &.grid-view {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 10px;

          .review-card {
            min-width: 375px;
            max-width: 375px;
          }

        }
    .review-card {
      min-width: 320px;
      max-width: 320px;
      min-height: 300px;
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
  
    .modal-content {
      position: relative;
      
      .modal-image {
        width: 100%;
      }
      .modal-close-button {
        right: 155px;
      }
    }
  }
  }

  
    .suggestions-section {
      
      .suggestions-slider {
        padding: 10px 0;
  
        .suggestion-card {
           min-width: 150px;
          max-width: 200px;
          }
        }
      }
      .disclaimer{
        .p-disclaimer{
          margin-right: 40px;
          font-size: 12px;
        }
      }
    }
  }