@import 'variables';

.variant-card-overlay {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .variant-card {
    background-color: $background-primary;
    padding: 20px;
    margin-top: 50px;
    width: 90%;
    max-width: 400px;
    max-height: 85vh;
    border-radius: 10px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    .variant-card::-webkit-scrollbar {
      width: 8px;
    }

    .variant-card::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
  
  .variant-stock {
      font-size: 0.9rem;
      text-align: left;
      color: $primary-color;
    }

  .error-message {
      color: $error-text;
      font-size: 0.875rem;
      text-align: left;
      margin-top: 10px;
    }

  h3{
      font-size: 1.5rem;
      color: $primary-text;
      text-align: left;
    }

  
    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 24px;
      color: $primary-color;
    }
  
    .variant-card-image {
      width: 275px;
      height: 275px;
      margin: 0 auto 10px auto;
      border-radius: 8px;
    }
  
    .variant-price {
      font-size: 1.3rem;
      color: $primary-text;
      margin: 5px 0 0 0;
      text-align: left;

    }
  
    h4 {
      font-size: 1rem;
      margin: 10px 0;
      color: $primary-text;
      text-align: left;

    }
  
    .variant-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 20px;
      
      .variant-option {
        padding: 8px 12px;
        border: 1px solid #ccc;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.3s ease;
        font-size: 1rem;

        &:hover,
        &.selected {
          background-color: $primary-color;
          color: $background-primary;
        }

        &.disabled {
          background-color: $background-primary;
          color: #ccc;
          cursor: not-allowed;
          pointer-events: none; // Makes it truly unclickable
        }

      }
    }
  
    .quantity-control {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      margin: 15px 0;
  
      button {
        width: 30px;
        height: 30px;
        font-size: 18px;
        display: flex;
          align-items: center;
          justify-content: center;
          background-color: $background-primary;
          border: 1px solid #ccc;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          &:hover {
            background-color: $primary-color;
            color: $background-primary;
          }
    }
    span {
      font-size: 16px;
    }
  }

    .confirm-add-to-cart {
      padding: 12px 20px;
      font-size: 1.4rem;
      background-color: $primary-color;
      color: $background-primary;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      width: 100%;
      transition: background-color 0.3s ease;
      
      &:hover {
        background-color: $primary-color;
      }

    }
  }

  .out-of-stock-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
  }
  
  .out-of-stock-modal {
    background-color: $background-primary;
    padding: 20px;
    max-width: 350px;
    width: 90%;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);

    .out-of-stock-icon {
      color: $primary-color;
      margin-bottom: 5px;
    }
    

    .os-text {
      font-size: 1rem;
      color: $primary-text;
      margin-bottom: 20px;
      font-weight: bold;
    }
  
    .os-close-btn {
      padding: 10px 20px;
      margin-top: 20px;
      width: 100%;
      font-size: 1rem;
      background-color: $primary-color;
      color: $background-primary;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
  