@import 'variables';

.faq-page {
  max-width: 850px;
  margin: 95px auto;
  padding: 20px;
  background-color: $background-primary;
  color: $primary-text;

  .faq-header {
    border-bottom: 2px solid $primary-color;
    padding-bottom: 10px;
    margin-bottom: 20px;

    h1 {
      font-size: 2rem;
      color: $primary-color;
    }
  }

  .faq-section {
    margin-bottom: 30px;
    max-width: 850px;
    min-width: 850px;

    .faq-title {
      font-size: 1.6rem;
      color: $primary-color;
      margin-bottom: 15px;
    }

    .faq-item {
      .faq-question {
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 10px 0;
        color: $primary-text;

        span{
          font-size: 1.3rem;
        }

        .toggle-button {
          background: none;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
          color: $primary-color;
        }
      }

      .faq-answer {
        padding: 10px 0;
        font-size: 1rem;
        line-height: 1.6;
        color: $primary-text;

        p {
          margin: 0;
        }
        ul {
          margin: 10px 0 10px 20px;
          padding-left: 20px;
          list-style-type: disc;
          color: $primary-text;
      
          li {
            margin-bottom: 5px;
            color: $primary-text;
          }
        }
        
      }

      .faq-divider {
        border: 0;
        border-top: 1px solid $primary-color;
        margin: 10px 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .faq-page {
    padding: 15px;
    width: 100%;

    .faq-header h1 {
      font-size: 1.5rem;
    }

    .faq-section {
      width: 100%;
      min-width: unset;
    
    .faq-title {
      font-size: 1.5rem;
    }

    .faq-item {
      .faq-question
        span{
        font-size: 1.2rem;
      }

      .faq-answer {
        font-size: 0.95rem;

        ul {
          margin-left: 15px;
        }
      }
    }
  }
}
}
