@import "variables";

.thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: auto;
  margin-top: 150px;
  justify-content: center;
  background-color: $background-primary;
  padding: 20px;
}

.ty-logo {
  width: 180px;
  margin-bottom: 20px;
}

.ty-title {
  font-size: 24px;
  font-weight: bold;
  color: $primary-text;
  margin-bottom: 10px;
}

.ty-message {
  font-size: 16px;
  color: $primary-text;
  width: 80%;
  margin-bottom: 20px;
}

.ty-instruction {
  margin-bottom: 20px;
}

.ty-instruction-text {
  font-size: 14px;
  color: $secondary-text;
  margin-bottom: 10px;
}

.ty-gif {
  width: 475px; 
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ty-button {
  background-color: $primary-color;
  color: #fff;
  padding: 12px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: background 0.3s ease;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

@media only screen and (max-width: 768px) {
  .ty-title {
    font-size: 20px;
  }

  .ty-message {
    font-size: 14px;
    width: 100%;
  }

  .ty-gif {
    width: 400px;
    height: 172px;
  }

  .ty-button {
    font-size: 14px;
    padding: 10px 15px;
  }
}
