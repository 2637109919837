@import 'variables';

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../Components/Assets/background.png') no-repeat center center;
  background-size: cover;
}

.signup-card {
  background-color: transparent;
  padding: 2rem;
  border-radius: 8px;
  width: 500px;
  text-align: center;
}

.signup-logo {
  width: 180px;
  margin-bottom: 1.5rem;
}

.signup-title {
  margin-bottom: 5px;
  text-align: left;
  color: $primary-text;
}

.signup-text {
  margin-bottom: 1rem;
  text-align: left;
  color: $secondary-text;
  font-size: 13px;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.signup-input {
  padding: 0.5rem 0;
  border: none;
  border-bottom: 1px solid $primary-text;
  font-size: 1rem;
  color: $primary-text;
  outline: none;
  text-align: left;
  background-color: transparent;
}

.signup-phone-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .phone-code {
    width: 50px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    border-bottom: 1px solid $primary-text;
    color: $primary-text;
    background-color: transparent;
    outline: none;
  }

  .phone-number {
    flex: 1;
    padding-left: 0.5rem;
  }
}


.sign-up-password-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.sign-up-password-container input {
  width: 100%;
  padding-right: 2.5rem;
}

.sign-up-eye-icon {
  position: absolute;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: $primary-text;
  font-size: 1.2rem;
  padding: 0.2rem;
}

.sign-up-eye-icon:focus {
  outline: none;
}

.signup-button {
  background-color: $primary-color;
  color: $background-primary;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}

.ca-terms-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;

  .ca-terms{
    font-size: 0.9rem;
    font-family: sans-serif;
    text-align: left;
  }
}

.ca-terms-checkbox {
  transform: scale(1.2);
  background-color: transparent;
  accent-color: $primary-color;
}

.ca-error-message {
  color: $error-text;
  font-size: 0.9rem;
  margin-top: 0.2rem;
  text-align: left;
}

.su-back {
  display: inline-block;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: $primary-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.bni-info {
  font-size: 0.9rem;
  text-align: left;

  a {
    color: $primary-color;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}

.bni-label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.bni-logo {
  width: 35px; 
  height: auto;
}




@media only screen and (max-width: 768px)  {

  .signup-card {
    width: 415px;
  }
}